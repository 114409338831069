.login-box {
  width: 550px;
  background: black;
  box-shadow: 0px 0px 15px 2px #cd292e85;
  border-radius: 10px;
  padding: 20px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 26px;
}

.login-box .user-box {
  /* position: relative; */
  /* border: 2px solid white; */
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}
.login-box button {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%) !important;
  border: none !important;
}


@media(max-width: 678px){
  .login-box{
    width: 98%;
  }
}