@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.profile {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 20px;
}
.profileburger {
  display: none;
}
.user_profile_sidebar {
  box-shadow: 0px 0px 12px 0px rgba(205, 41, 46, 0.45);
  border-radius: 20px;
  padding: 0px 10px !important;
}
.active_profiel_sidebar {
  color: white !important;
  box-shadow: 0px 0px 12px 2px rgba(205, 41, 46, 0.47);
}
.profiel_sidebar_menu:hover {
  color: white !important;
  box-shadow: 0px 0px 12px 2px rgba(205, 41, 46, 0.47);
}
.profiel_sidebar_menu p {
  font-family: Arial !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.h_text {
  color: white !important;
  font-family: Arial !important;
  letter-spacing: 1px;
}
.selected_item {
  /* border: 2px solid rgba(205, 41, 46, 0.47);
  box-shadow: 1px 1px 5px 0px rgba(205, 41, 46, 0.47); */
  background-color: rgba(205, 41, 46, 0.2) !important;
}
@media (max-width: 1100px) {
  .table {
    border: 2px solid blue;
    width: 1000px;
    overflow-x: auto;
  }
}

@media (max-width: 768px) {
  .profile {
    grid-template-columns: 0.1fr 1fr;
  }
  .profileSidebar {
    width: 70px !important;
    min-width: 70px !important;
    padding: 0px 0px !important;
  }
  .profileSidebar p {
    display: none !important;
  }
  .sidebar_profile_box {
    padding: 10px 0px !important;
  }
  .profiel_sidebar_menu {
    justify-content: center !important;
  }
  /* .profileSidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 350px !important;
    transform: translateX(-100%);
    transition: transform 0.1s linear;
    z-index: 20;
  }
  .profileSidebar_mobile {
    transform: translateX(0%);
    transition: transform 0.1s linear;
  }
  .profileburger {
    display: block;
    border: 2px solid white;
  } */
}

@media (max-width: 500px) {
  .profileSidebar {
    width: 100% !important;
  }
  .profile {
    padding: 20px 10px;
    gap: 5px !important;
  }
}
