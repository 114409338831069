@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */
      url("../../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}
@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  src: url("../../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../../assets/fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.ticketsGroup {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 20;
}
.headermenus_ticket_parent button {
  font-family: Tahoma !important;
  font-weight: 400;
  text-align: center;
}

/* ===================== categorysidebar */
.categorysidebar {
  box-shadow: 0px 0px 15px 1px rgba(205, 41, 46, 0.45);
  border-radius: 20px;
  padding: 10px 20px;
  max-width: 350px;
  margin-top: 10px;
}
.filter_text {
  font-family: Arial;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.date_text {
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 7px;
}
.categorysidebar input {
  border: none !important;
  box-shadow: 0px 0px 5px 2px rgba(205, 41, 46, 0.45);
  width: 100%;
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px !important;
  text-align: center !important;
  color: white !important;
  max-width: 130px;
}

.categorysidebar ::-ms-input-placeholder {
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px !important;
  text-align: center !important;
  color: white !important;
}

.categorysidebar ::placeholder {
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px !important;
  text-align: center !important;
  color: white !important;
}
.filter-f-btn {
  box-shadow: 0px 0px 5px 2px rgba(205, 41, 46, 0.45);
}
.filter-f-btn:hover {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%);
}

/* -------- media  */
@media (max-width: 1380px) {
  .ticket_page {
    padding: 0px 30px;
  }
  .categorysidebar input {
    max-width: 110px;
    font-size: 14px !important;
  }
  .categorysidebar ::-ms-input-placeholder {
    font-size: 14px;
  }

  .categorysidebar ::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 1050px) {
  .ticketsGroup {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1000px) {
  .headermenus_ticket {
    width: 900px;
  }
  .headermenus_ticket_parent {
    overflow-x: auto !important;
  }
}

@media (max-width: 550px) {
  .ticket_page {
    padding: 0px 10px;
  }
  .categorysidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    padding: 20px 10px !important;
    height: 100vh;
  }
}
