@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../assets//fonts//Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */
      url("../../../assets//fonts/Tahoma\ Regular\ font.ttf") format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.new_header {
  box-shadow: 0px 0px 23px 1px #cd292e85 !important;
  /* position: sticky;
  top: 0; */
  border-bottom: 1px solid #cd292e42 ;
}

.logobluer {
  animation: blurAnimation 2s linear infinite;
}
.header {
  position: sticky;
  top: 0;
}


.category_parent::-webkit-scrollbar{
  width: 10px !important;

}

html .category_parent{
  scrollbar-width: thin !important;
  scrollbar-color: red black !important;
}


@keyframes blurAnimation {
  0%,
  100% {
    filter: blur(25px);
  }
  50% {
    filter: blur(15px);
  }
}
/* ======== searchbarbox */
.searchbarbox {
  box-shadow: 0px 0px 5px 0.5px #cd292e85;
}
.searchbarbox input {
  font-family: Tahoma;
}

/* ----- new_menus */
.new_menus li {
  list-style: none;
  padding: 2px 2px 5px 5px;
  margin: 0px 10px;
  cursor: pointer;
  font-size: 15px;
  font-family: Tahoma;
}
.new_menus a{
  color: #7B7583;
}
.new_menus .active {
  color: #cd292e !important;
  border-bottom: 1px solid #cd292e !important;
}
/* .new_menus li:first-child {
  color: #cd292e !important;
  border-bottom: 1px solid #cd292e;
} */

/* ---- btn  */
.account_btn1 {
  color: #cd292e;
  border-radius: 10px;
  padding: 6px 9px;
  font-size: 14px;
  font-family: Tahoma;
  border: 1px solid black;
}
.account_btn2 {
  color: white;
  background-color: #cd292e;
  border-radius: 10px;
  padding: 6px 9px;
  font-size: 14px;
  font-family: Tahoma;
}
.account_btn2:hover {
  background-color: #951d21;
}
.account_btn1:hover {
  border: 1px solid #cd292e;
}
.header_menus {
  display: none;
}
.mob_header {
  display: none;
}

/* ======================================  */

.logo {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.sidebar_nav a {
  border-bottom: 1px solid rgba(255, 0, 0, 0.164) !important;
}

/* ============= media querry  */
@media (max-width: 1380px) {
  .header {
    padding: 4px 30px !important ;
    position: sticky;
  }
}
@media(max-width : 1280px){
  .new_menus li {
    font-size: 15px !important;
  }
  .right_header{
    gap: 20px !important;
  }
}

@media (max-width: 1210px) {
  .serachbar {
    min-width: 450px !important;
  }
  .username {
    font-size: 14px;
  }
}
@media(max-width : 1140px){
  .new_menus li {
    font-size: 13px !important;
  }
}
/* --- extra  */
@media (max-width: 1076px) {
  .right_header {
    gap: 10px !important;
  }
  .new_menus li {
    font-size: 14px;
    margin: 0px 7px;
  }
}

@media (max-width: 1050px) {
  .new_menus{
    display: none;
  }
  .header_menus {
    position: fixed;
    top: 0;
    left: 0;
    width: 350px;
    height: 100vh;
    z-index: 192;
    flex-direction: column;
    padding: 50px 20px;
    transform: translateX(-110%);
    transition: transform 0.2s linear;
    display: block !important;
    box-shadow: 0px 0px 10px 1px red;
  }
  .login_mobile {
    display: block;
  }
  .mobileHeader {
    transform: translateX(0%);
    transition: transform 0.2s linear;
  }
  .header_menus nav {
    flex-direction: column;
    justify-content: flex-start;
  }
  .header_menus nav a {
    width: 100%;
    padding: 10px 0px;
    margin: 1px 0px;
    border-bottom: 1px solid black;
  }

  /* ------- serachbar */
  .sub_header {
    /* display: grid !important;
    grid-template-columns: repeat(2, 1fr); */
    flex-wrap: wrap;
  }
  .serachbar {
    order: 3;
    width: 100%;
    margin: 0px auto;
  }
  .right_header {
    /* width: 250px; */
    display: none;
  }
  .logo {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .logo svg {
    display: block !important;
  }
  .cross_burger {
    display: block;
  }
  .mob_header {
    display: block;
  }
}

@media (max-width: 768px) {
  .tab_searchbarbox {
    display: none !important;
  }
}

@media (max-width: 678px) {
  .header {
    padding: 4px 10px !important;
  }
  .serachbar {
    min-width: 100% !important;
    width: 100% !important;
  }
}

/* ------ extra media  */
@media (max-width: 590px) {
  .username {
    display: none;
  }
  .right_header a button {
    display: none;
  }
  .right_header a svg {
    display: block;
  }
  .right_header {
    width: 50px !important;
  }
  .header_menus {
    width: 100% !important;
  }
  .mob_logo {
    width: 150px !important;
  }
  .logobluer {
    width: 150px !important;
  }
}
