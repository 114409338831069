#seatics-map-container {
    display: block !important;
  }
  
  #sea-mp {
    display: block !important;
    height: 100% !important;
    width: 100% !important;
  }

#venue-map-reset-ctn .venue-map-reset-icon{
    color: black ;
  }
  
  @media screen and (min-width: 992px) {
    #sea-mp {
      display: block !important; /* Force the map to show on large screens */
    }
  }
  