@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  src: url("../../../assets//fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets//fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}
@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}






.index_deatils {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  justify-content: flex-start;
  align-items: flex-start;
}

.parent_seat_map_wraper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 20; */
}

.mapclass {
  /* border: 2px solid white; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  /* z-index: 20 !important; */
}
.map_wraper {
  /* width: 100%;
    height: 90vh;
    overflow: hidden;
    border: 2px solid purple;
    background-color: white;
    position: relative; */
  width: 100%;
  height: 100%;
  background-color: red;
}
#Calque_1 {
  z-index: 999 !important;
}
.evet_namesidebar {
  font-family: Arial !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left;
  margin-bottom: 8px;
}
.evet_parasidebar {
  font-family: Tahoma !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}

.bg_linear{
 background:  linear-gradient(
      0deg,
      rgba(130, 19, 23, 0.4),
      rgba(130, 19, 23, 0.4)
    ),
    linear-gradient(
      94.21deg,
      rgba(205, 41, 46, 0.8) 1.47%,
      rgba(205, 41, 46, 0.4) 98.9%
    )
}
.location_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: linear-gradient(
      0deg,
      rgba(130, 19, 23, 0.4),
      rgba(130, 19, 23, 0.4)
    ),
    linear-gradient(
      94.21deg,
      rgba(205, 41, 46, 0.8) 1.47%,
      rgba(205, 41, 46, 0.4) 98.9%
    );
  padding: 5px 8px;
  border-radius: 12px;
  margin: 0px;
}
.location_div span {
  font-family: Tahoma !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  color: white !important;
}
.allticket_sidebar_text {
  font-family: Arial !important;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: left;
  /* color: rgba(255, 255, 255, 0.8) !important; */
}
.sidebrticket_box {
  /* box-shadow: 0px 0px 8px 0px #cd292e85; */
  border: 1px solid #cd292e85 !important;
}
.sidebar_price_tikcet {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.sidebar_ticket_name {
  font-family: Arial;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.ticket_available_count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
}
.checkoutsidebar_header h2 {
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}
.paymentmethodsidebardiv {
  margin: 20px 0px;
}
.paymentmethodsidebardiv h2 {
  font-family: Arial;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.paymentmethodboxchild {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 12px 10px;
  /* border: 1px solid rgba(83, 20, 20, 1) !important; */
  border-radius: 12px;
  box-shadow: 0px 0px 6px 0px rgba(83, 20, 20, 1);
  cursor: pointer;
}
.paymentmethodboxchild:hover {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%);
  color: white !important;
}
.paymentmethodbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0px;
}
.paymentmethodboxchild p {
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.btn_checkout {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%);
  border-radius: 12px !important;
}
.d_h {
  font-family: Arial;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 5px 0px;
}
.d_p {
  font-family: Tahoma;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.apply_bg {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%) !important;
}
.checkout_sidebar_inner {
  border-right: 1px solid #cd292e;
  background-color: black !important;
}

.siebar_image_div {
  display: none;
}
.chactout_sidebar{
  scrollbar-width: thin !important;
  scrollbar-color: red black !important;
}


/* -------- OR_Text */
.OR_Text{
  position: relative;
}
.OR_Text::before{
  content: "";
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 17px;
  background-color: white;
  opacity: 1 !important;
  border-radius: 12px;
}
.OR_Text::after{
  content: "";
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 17px;
  background-color: white;
  opacity: 1 !important;
  border-radius: 12px;
}



/* ============ media  */
@media (max-width: 850px) {
  .index_groupdeatails {
    height: auto !important;
    overflow: auto !important;
  }
  .index_deatils {
    grid-template-columns: 1fr;
    overflow: auto !important;
  }
  .chactout_sidebar {
    order: 2;
    width: 100% !important;
    height: auto !important;
    overflow: auto !important;
   
  }
  .checkout_sidebar_inner {
    position: fixed !important;
    z-index: 20;
    height: 100vh !important;
  }
  .deatils_sidebar {

  }
}

@media (max-width: 550px) {
  .payment_modal {
    width: 100% !important;
    margin: 20px 10px !important;
  }
  .checkout_sidebar_inner {
    width: 100% !important;
  }
  .filtersidebar {
    width: 100% !important;
    margin: 0px !important;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .hide_image_div_in_mobile {
    display: none;
  }
  .siebar_image_div {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    height: 400px;
  }
}
