@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../assets//fonts//Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("../../assets//fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.new_category {
}
.new_category_parent_parent {
  overflow-x: auto;
  overflow-y: hidden;
}


.arrow_svg {
  display: none !important;
}

.s_category_box:hover .sub_category_options {
  display: block !important;
}

.new_category_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
  margin: 0px 0px;
}
html .new_category_parent{
  scrollbar-width: none !important;
}
html .dropdown-contents {
  scrollbar-width: thin !important;
  scrollbar-color: red black !important;

  
}
.new_category h2 {
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 15px !important;
  text-transform: uppercase;
}

.new_category_box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 10px 15px;
  border-radius: 20px;
}
.new_category_box svg {
  font-size: 26px;
  color: #cd292e;
}
.new_category_box p {
  font-family: "Montserrat", sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}
/* .new_category_box:hover svg {
  color: #cd292e;
}
.new_category_box:hover p {
  color: #cd292e;
} */

/* ========== sub_category_options */
.sub_category_options {
  /* position: absolute;
  bottom: -78%;
  left: 0; */
  width: 100% !important;
  border: 2px solid #cd292e;
  padding: 10px 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.arrow_svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: 30px;
  color: #cd292e;
}

@media (max-width: 1300px) {
  .new_category_parent {
    justify-content: center !important;
  }
  .sub_category_options {
    justify-content: flex-start !important;
    max-width: 1200px !important;
  }
}

@media(max-width : 1200px){
  .main_p_category{
    min-width: 140px !important;
    width: 140px !important;
  }
}
@media(max-width : 1130px){
  .new_category_parent {
    overflow-x: auto !important;
    overflow-y: hidden !important;
    justify-content: flex-start !important;
  }
  .main_p_category{
    min-width: 160px !important;
    width: 160px !important;
  }
  .dropdown-content {
    display: none !important;
  }

}


@media (max-width: 500px) {
  .new_category h2 {
    letter-spacing: 5px !important;
  }
}
