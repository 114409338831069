@font-face {
  font-family: "Arial";
  /* Choose a name for your font */
  src: url("../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Arial/arial.ttf")
      format("woff");
  /* Older Browsers */
  /* Add additional formats if needed */
}

@font-face {
  font-family: "Tahoma";
  /* Choose a name for your font */
  src: url("../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Tahoma\ Regular\ font.ttf")
      format("woff");
  /* Older Browsers */
  /* Add additional formats if needed */
}

.LatestEventCard {
  width: 300px;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(205, 43, 48, 1);
}

.LatestEventCardNewCardDesign {
  width: 300px;
  height: 420px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.1s linear;
}

.LatestEventCardNewCardDesign:hover {
  transition: transform 0.1s linear;
  transform: scale(0.96);
}
/* --- card_uper_vector1 */
.card_uper_vector1 {
  /* border-radius: 8px 8px 50px 8px ; */
  clip-path: polygon(115% 0, 0 0, 0 115%);
  border-radius: 4px 4px 0px 4px;
}
.db_image {
  /* clip-path: polygon(19% 0, 100% 0, 100% 68%, 71% 100%, 0 100%, 0% 60%, 0 13%); */
  /* clip-path: polygon(19% 0, calc(100% - 7px) 0, 100% 68%, 100% calc(100% - 7px), 57% 100%, 7px 100%, 0% 60%, 0 13%); */
  /* clip-path: polygon(19% 0%, calc(100% - 9px) 0%, 100% 68%, 100% calc(100% - 121px), 57% 100%, 35px 100%, 0% 60%, 0 13%); */
  padding: 0px 10px;
  height: 100%;
  width: 100%;
  z-index: 20 !important;
  position: relative;
}
.clip_path {
  width: 250px;
  height: 332px;
  /* clip-path: polygon(70.71% 100%, 100% 70.71%, 100% 29.29%, 100% 0%, 29.29% 0%, 0% 29.29%, 0% 70.71%, 0% 100%); */
  clip-path: polygon(19% 0, 100% 0, 100% 68%, 71% 100%, 0 100%, 0% 60%, 0 13%);
  background-color: #eb8b47;
  overflow: hidden;
  margin: 0px auto;
  z-index: 50 !important;
  position: relative;
}

.card_bottom_vector1 {
  border-radius: 16px 8px 8px 8px;
  /* clip-path: polygon(105% 0, 0 0, 0 105%); */
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
.imageoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(180.07deg, rgba(0, 0, 0, 0) 17.96%, #8A0000 99.94%);
  padding: 0px 10px; */
}
.new_card_footer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.new_card_btn {
  margin-top: 4px;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: #c10f0f;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 0em;
  text-align: left;
}

.date_of_new_card {
  font-family: Krick !important;
  font-size: 35px;
  font-weight: 700;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -20px;
}

.month_of_new_card {
  /* font-family: Arial !important; */
  font-family: Krick !important;
  font-size: 17px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -10px;
}

.time_text_of_new_card {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 800;
  line-height: -36px;
  letter-spacing: -0.03em;
  text-align: left;
}

.time_of_new_card {
  background-color: white;
  color: black;
  padding: 1px 3px 1px 3px;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
  min-width: 53px;
}
.Name_of_the_event {
  font-family: Arial !important;
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  line-height: -60;
}
/* ============= */

.LatestEventCard img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.2s linear;
}

.LatestEventCard .overlaynew {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(30, 30, 30, 0.32);
  width: 300px;
  height: 400px;
  z-index: 1;
  display: none;
}

/* ---- LatestEventCard_top_right_section */
.LatestEventCard_top_right_section {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.LatestEventCard_top_right_section_box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 9px;
  gap: 7px;
  background: linear-gradient(
      0deg,
      rgba(130, 19, 23, 0.4),
      rgba(130, 19, 23, 0.4)
    ),
    linear-gradient(
      94.21deg,
      rgba(205, 41, 46, 0.8) 1.47%,
      rgba(205, 41, 46, 0.4) 98.9%
    );
  width: fit-content;
}

.LatestEventCard_top_right_section_box p {
  font-family: Tahoma;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.LatestEventCard_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  z-index: 2;
  background: rgba(205, 43, 48, 1);
}

/* ===========  */
.ticket_box_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: none;
}

.LatestEventCard:hover .LatestEventCard_top_right_section {
  display: none;
}

.LatestEventCard:hover .LatestEventCard_footer {
  display: none;
}

.LatestEventCard:hover .ticket_box_content {
  display: block;
}

.LatestEventCard:hover .overlaynew {
  display: block !important;
}

.LatestEventCard:hover img {
  transform: scaleX(1.3);
  transform-origin: left;
  transition: transform 0.2s linear;
}

/* ============  */
.inner_ticket_location {
  background: white !important;
  color: black !important;
  padding: 8px !important;
  border-radius: 12px !important;
}

.inner_ticket_location p {
  font-family: Tahoma;
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(205, 43, 48, 1);
}

.ticket_inner_heading {
  font-family: Arial;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.ticktet_inner_btn {
  position: absolute;
  bottom: -2px;
  left: 44px;
  width: 100%;
  background-color: white;
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 260px 260px 0px 0px;
  height: 40px;
  width: 170px;
}
