@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  /* eslint-disable-next-line */
  src: url("../../../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../../../assets/fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}
@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../../../assets//fonts//Tahoma\ Regular\ font.ttf")
      format("woff2"),
    /* Modern Browsers */
      url("../../../../../assets//fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.Carousal02 {
  margin: 10px 0px !important;
  overflow: hidden;
  /* box-shadow: 0px 0px 5px 1px #cd292e; */
  position: relative;
  margin: 0px auto;
  max-width: 1000px;
}
.Carousal02:hover {
  /* box-shadow: 0px 0px 20px 2px #cd292e !important; */
}
.carousalbox {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  gap: 30px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.carousalbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousalbox_content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.carousalbox_locations {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 18px 0px;
}
.carousalbox_locations_box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.carousalbox_locations_box svg {
  font-size: 19px;
}
.carousalbox_locations_box p {
  font-size: 18px;
  font-weight: 400;
  z-index: 50;
  font-family: Tahoma !important;
}
.carousalbox_content h1 {
  z-index: 50;
  font-family: Arial !important;
}
.carousalbox_content p {
  z-index: 50;
  font-family: Tahoma !important;
  max-width: 500px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none !important;
}
.swiper-pagination {
  position: absolute !important;
  bottom: 20px !important;
  right: 20px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  padding: 10px 80px;
}

.swiper-pagination-bullet {
  width: 14px !important;
  height: 14px !important;
  background-color: #cd292e !important;
}

.swiper-pagination-bullet-active {
  width: 22px !important;
  height: 22px !important;
}

/* =======================  */

@media (min-width: 1700px) {
  .Carousal02 {
    max-width: 1500px;
  }
}

@media (max-width: 1250px) {
  .carousalbox {
    grid-template-columns: 1fr 0.7fr;
    gap: 20px;
  }
}
@media (max-width: 1150px) {
  .carousalbox {
    grid-template-columns: 1fr 0.8fr;
    gap: 20px;
  }
  .carousalbox_locations_box p {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .Carousal02 {
    height: auto !important;
  }
  .carousalbox {
    grid-template-columns: 1fr;
  }
  .carousalbox_content {
    padding: 20px 20px;
  }
}

@media (max-width: 550px) {
  .swiper-pagination {
    bottom: -20px !important;
    right: 0px !important;
    padding: 10px 10px;
  }
  .carousalbox_content h1 {
    font-size: 25px;
  }
  .carousalbox_content p {
    font-size: 15px;
  }
  .carousalbox_locations_box p {
    font-size: 12px;
  }
  .carousalbox_locations_box {
    gap: 2px;
  }
  .carousalbox_content {
    padding: 10px 10px;
  }
  .carousal_content_btn {
    margin: 0px !important;
    margin-bottom: 50px !important;
    padding: 10px 50px !important;
  }
}
