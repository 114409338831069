.feeds_heading {
  font-family: Cabinet Grotesk;
  font-size: 48px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px 0px;
}
.Feeds_hero img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid red;
  margin: 20px 0px;
}
.feeds_content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  gap: 20px;
  padding: 20px 0px;
}

/* -------- feeds_content_left */
.feeds_content_left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.feeds_content_left h2 {
  font-size: 25px;
  font-weight: 700;
  margin: 10px 0px;
}
.feeds_content_left h3 {
  font-size: 20px;
  margin: 30px 0px;
}
.feeds_content_left p {
  margin: 5px 0px;
  color: rgb(204, 202, 202);
  letter-spacing: 1px;
  margin: 20px 0px;
}

/* --------- feeds_content_right */
.feeds_content_right {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.feeds_content_right_box {
  width: 100%;
}
.feeds_content_right_box img {
  min-width: 100%;
  /* object-fit: fill; */
  height: 250px;
  border-radius: 15px;
  margin-bottom: 15px;
}
.feeds_content_right_box_content {
  padding-left: 10px;
}
.feeds_content_right_box_content h2 {
  font-family: Cabinet Grotesk;
  font-size: 17px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.feeds_content_right_box_content p {
  font-family: Cabinet Grotesk;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: gray;
}
.feeds_content_right_box_content button {
  color: #a60d0d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  margin-top: 7px;
}

/* -- media  */
@media (max-width: 1000px) {
  .feeds_parent {
    padding: 10px 20px !important;
  }
}
@media (max-width: 850px) {
  .feeds_parent {
    margin: 10px 0px !important;
  }
  .feeds_heading {
    margin: 10px 0px !important;
    font-size: 30px;
  }
  .feeds_content {
    grid-template-columns: 1fr;
  }
  .feeds_content_right_box {
    display: flex;
    flex-direction: row !important;
  }
  .feeds_content_right_box img {
    min-width: 250px !important;
    height: 200px;
  }
  .feeds_content_right_box_content {
    display: flex;
    justify-content: flex-start;
    gap: 7px;
    align-items: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .Feeds_hero img {
    width: 100%;
    height: auto;
  }
  .feeds_parent {
    padding: 10px 10px !important;
  }
  .feeds_content_right_box img {
    min-width: 100% !important;
    height: 220px;
  }
  .feeds_content_right_box {
    flex-direction: column !important;
  }
}
