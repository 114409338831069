@media (max-width: 1000px) {
  .main_home {
    padding: 0px 20px !important;
  }
}
@media (max-width: 550px) {
  .main_home {
    padding: 0px 10px !important;
  }
}
