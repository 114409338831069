.trendingEvent {
  margin: 70px 0px;
}
.t_h {
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 15px !important;
  text-transform: uppercase;
  text-align: center;
}
@media (max-width: 550px) {
  .t_h {
    letter-spacing: 5px !important;
    font-size: 30px;
  }
}
