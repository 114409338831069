.latest_event {
  margin: 50px 0px;
}
.latest_event_heading {
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 15px !important;
  text-transform: uppercase;
  align-items: center;
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 50px;
}
.scrollbar-hide::-webkit-scrollbar {
  /* display: none;  */
}
@media (max-width: 550px) {
  .latest_event_heading {
    letter-spacing: 5px !important;
    font-size: 30px;
  }
}
