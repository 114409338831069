@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
}
.active {
  color: red !important;
}
body {
  background-color: black;
  overflow-x: hidden !important;
  /* font-family: Tahoma !important; */
  font-family: "Fredoka", serif !important;
}
body::-webkit-scrollbar{
  width: 10px !important;
}


h2,
h1,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif !important;
}
p {
  font-family: "Poppins", sans-serif !important;
}


html {
  scrollbar-width: thin !important;
  scrollbar-color: red black !important;
}
html:hover{
  scrollbar-width: thin !important;
  scrollbar-color: red black !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: none; */
  -webkit-text-fill-color: #F1F1F1;
  -webkit-box-shadow: 0 0 0px 1000px black inset;
  transition: background-color 5000s ease-in-out 0s;
};



@tailwind base;
@tailwind components;
@tailwind utilities;

/* .goog-te-gadget-simple {
  position: fixed;
  top: 0;
  right: 1px;
  width: 150px;
  display: flex !important;
  z-index: 99999;
}
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  display: flex;
} */

.horizontal .eg-affiliate-banners-frame{
   width: 728px !important; 
   height: 90px !important;
}


@media(max-width : 678px){
  .horizontal .eg-affiliate-banners-frame{
    width: 280px !important; 
    height: 250px !important;
 }
 .responsivehidden .eg-affiliate-banners-frame{
  display: none !important;
 }
}