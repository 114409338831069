@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}
@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.trending_card {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.trending_card img {
  width: 100%;
  height: 100%;
}
.TC_footer_btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 159px;
  left: 0;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  border-radius: 15px;
  padding: 20px 20px;
  background: linear-gradient(
      0deg,
      rgba(130, 19, 23, 0.2),
      rgba(130, 19, 23, 0.2)
    ),
    linear-gradient(
      93.42deg,
      rgba(205, 41, 46, 0.8) 2.01%,
      rgba(205, 41, 46, 0.4) 98.58%
    );
  transform: translateY(50%);
  z-index: 2;
  transition: all 0.1s linear;
  /* animation: bounce2 0.5s linear 1; */
  animation-delay: 0.2s;
}
.live_box p {
  letter-spacing: 1px;
}
.live_box {
  padding-top: 30px;
}
.trending_content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
}
.trending_content h2 {
  font-family: Arial;
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 124, 129, 1);
  text-align: center;
  opacity: 0;
}
.trending_content p {
  font-family: Tahoma;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.32);
  z-index: 1;
  display: none;
}

.trending_card:hover .TC_footer_btn {
  transform: translateY(0%);
  transition: all 0.1s linear;
}
.bounce {
  transform: translateY(50%);
  animation: bounce2 0.2s linear 1;
  animation-delay: 0.1s;
}

.trending_card:hover .live_box {
  padding-top: 0px;
}
.trending_card:hover .trending_content h2 {
  opacity: 1;
}
.trending_card:hover .trending_content p {
  opacity: 1;
}
.trending_card:hover .overlay {
  display: block;
}

@keyframes bounce2 {
  0%,
  100% {
    transform: translateY(50%);
  }
  50% {
    transform: translateY(45%);
  }

  100% {
    transform: translateY(50%);
  }
}
