@font-face {
  font-family: "Arial"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Arial/arial.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Arial/arial.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}
@font-face {
  font-family: "Tahoma"; /* Choose a name for your font */
  src: url("../../../assets/fonts/Tahoma\ Regular\ font.ttf") format("woff2"),
    /* Modern Browsers */ url("../../../assets/fonts/Tahoma\ Regular\ font.ttf")
      format("woff"); /* Older Browsers */
  /* Add additional formats if needed */
}

.profile_AccountDetails {
}
.profile_AccountDetails h2 {
  font-family: Arial !important;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.profile_AccountDetails_image {
  box-shadow: 0px 0px 12px 2px rgba(205, 41, 46, 0.45);
  margin-bottom: 10px;
}
.profile_AccountDetails label {
  font-family: Tahoma !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 7px !important;
}
.profile_AccountDetails input {
  box-shadow: 0px 0px 12px 1px rgba(205, 41, 46, 0.45);
  border: none !important;
  background-color: transparent !important;
}
.profile_AccountDetails button {
  background: linear-gradient(135deg, #cd292e 0%, #951d21 100%) !important;
  border: none !important;
}

@media (max-width: 500px) {
  .profile_AccountDetails_image {
    width: 170px !important;
    height: 170px !important;
  }
  .profile_AccountDetails {
    padding: 8px 4px !important;
  }
}
