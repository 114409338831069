
@media(max-width:1050px){
    .footer2{
        display: grid !important;
        grid-template-columns: 1fr .4fr .4fr .5fr;
        row-gap: 50px;
    }
    .footer_contact{
        width: 100% !important;
    }
}
@media(max-width:900px){
    .footer2{
        grid-template-columns: repeat(3,1fr);
        column-gap: 20px;
    }
    .footer_Support{
        order: 3;
    }
    .footer_pages{
        order: 2;
    }
    .footer_categories{
        order: 1;
    }
    .footer_contact{
        grid-column: 2 / 4;
    }
}

@media(max-width : 830px){
    .bottom_footer{
        flex-direction: column;
        gap : 20px;
        justify-content : flex-start;
        align-items : flex-start
    }
}
@media(max-width:650px){
    .footer2{
       display: flex !important;
       flex-direction: column;
    }

}
@media(max-width : 500px){
    .footer_email{
        font-size: 12px;
    }
    .bottom_footer_bottom{
        display : none
    }
    .bottom_footer{
        align-items : center
    }
}
