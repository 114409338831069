.trendingEvent .swiper {
  width: 100%;
  height: 100%;
}

.trendingEvent .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
}

.trendingEvent .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
