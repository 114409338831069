


@media(max-width : 1000px){
    .SkeletonLoading_carousal{
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .SkeletonLoading_carousal_div01{
        height: 60vh;
    }
}